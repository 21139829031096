.delivery-header {
    height: 4rem;
    background-color: rgb(228 232 239);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
   
}

.delivery-header-logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.delivery-header-logo > img {
    max-height: 2.5rem;
  }

  .delivery-sidebar {
    @apply flex flex-col items-stretch justify-between;
    height: calc(100% - 4rem);
    width: 260px;
    position: fixed;
    z-index: 99;
    top: 4rem;
    right: -260px;
    /* background-color: #f6f6f7; */
    overflow-x: hidden;
    padding-top: 3rem;
    border-left: 1px solid #e1e3e5;
    background-color: rgb(255, 255, 255);
  }
  
    
  .delivery-sidebar a {
    padding: 10px 20px 6px 16px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;
    color: #5c5f62;
    display: block;
  }
  
  .delivery-sidebar a:hover {
    background-color: #1413138f;
    color: #dcefff !important;
  }


  @media (min-width:901px) {

    .delivery-burger {
        display: none;
    }       

    .delivery-sidebar{
      right: 0;
  }
    
  }  

  @media (max-width:901px) {
    .dSideBar-slide-left {
   
        -webkit-animation: slide-out 0.5s both;
        animation: slide-out 0.5s both;
       
    }
    
    .dSideBar-slide-right {
       
        -webkit-animation: slide-in 0.5s both;
        animation: slide-in 0.5s both;   
    }
    
  }