@tailwind base;
@tailwind components;
@tailwind utilities;

#webpack-dev-server-client-overlay {
  display: none;
}

[dir="rtl"] {
    direction: rtl;
    background: silver;
  
    .row [class*="col-"] {
      float: right;
    }
  }
  
  * {
    direction: rtl;
  }

  
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Tajawal-Regular';
}

.p-carousel-container {
  direction: ltr !important;
}

.p-carousel-items-container {
  direction: ltr;
}

.p-carousel-item {
  width: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  border-radius: 500px !important;
  border: 1px solid black;
  width: 11px !important;
  height: 11px !important;
  background-color: white !important;

}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
 
  background-color: #16ca88 !important;
  border-color: #16ca88;

}

.inputLabel {
  @apply text-sm block mb-2 font-semibold;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.card {
  @apply bg-white border rounded-xl border-gray-200 shadow-md p-6 mb-4;
}

.p-carousel-indicators  {
  gap : .2rem
}
