.ezone-offcanvas {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -270px;
    z-index: 1000;

}

 .ezone-slide-left {
   
    -webkit-animation: slide-out 0.5s both;
    animation: slide-out 0.5s both;
   
}


@-webkit-keyframes slide-out {
    0% { right: -270px; }
    100% { right: 0; }
}

@keyframes slide-out{
    0% { right: -270px; }
    100% { right: 0; }
}

.ezone-slide-right {
   
    -webkit-animation: slide-in 0.5s both;
    animation: slide-in 0.5s both;   
}


@-webkit-keyframes slide-in {
    0% { right: 0; }
    100% { right: -350px; }
    
}

@keyframes slide-in {
    0% { right: 0; }
    100% { right: -350px; }
}

