.register-input {
    border-radius: 5px;
    height: 45px !important;
    width: 100%;
    border: 1px solid #ced4da; 
    padding-right: 12px;
    padding-left: 12px;
}

.register-input:hover {
    border-color: #16ca88 !important;
}

.register-input:focus {
    outline: 0;
    background-color: #fff;
    color: #001f21;
    border-color: #16ca88 !important;
    box-shadow: 0 0 0 0.2rem #16ca8847 !important;
}

fieldset {
    border: 1px solid lightgray;
    padding: 1rem;
}