.delivery-body {

    padding-top: 4rem;
    padding-right: 16rem;
    padding-left: 3rem;

}

@media (max-width:901px) {

    .delivery-body {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
    
  }